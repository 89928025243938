import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MobilCarousel from "../components/mobil-carousel"
import Carousel from "../components/carousel"
import Companys from "../components/companys"
import Checkup from "../components/checkup"
import Testimonial from "../components/testimonial"
import whoWeAreImg from "../assets/img/murathan-yildirim-flatart.jpg";
import whoWeAreImg1 from "../assets/img/biz-kimiz.png";
import analyseImg from "../assets/img/main/veri-analizi.jpg";
import acquireImg from "../assets/img/main/yuksek-kaliteli-trafik.jpg";
import convertImg from "../assets/img/main/kazandiran-trafik.jpg";
import costFriendly from "../assets/img/cost-friendly.png";
import resultBased from "../assets/img/result-based-agency.png";
import shopifyEcommerce from "../assets/img/shopify-ecommerce-expert.png";
import transparencyIsKey from "../assets/img/transparency-is-key.png";
import squad from "../assets/img/squad.png";
import "../assets/scss/carousel.scss";
import SolvedSection from "../components/solved"

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: false,
      value: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    e.preventDefault();

    this.setState({ value: e.target.value });
  }
  showModal(e) {

    this.setState({ display: !this.state.display });
    document.querySelector('.check-modal').classList.add('active-modal');

    document.querySelector('#site').innerHTML = this.state.value;
  }

  closeModal() {
    this.setState({ display: !this.state.display })
    document.querySelector('.check-modal').classList.remove('active-modal');
  }

  render() {
    return (

      <Layout>
        <SEO title="Anasayfa" />
        <div className="caro" >
       
        <div className="desktop-component">
          <Carousel /> {/* Masaüstü bileşeni burada göster */}
        </div>
        <div className="mobile-component" >
          <MobilCarousel /> {/* Mobil bileşeni burada göster */}
        </div>
      </div>
      
        <div className="buttonnewss">
          <a href="/proje-baslat/" target="_blank">
            <button className="red-button newsss"> &nbsp; Proje Başlat &nbsp; </button>
          </a>
          <button onClick={(e) => this.showModal()} className="red-button newsss">Ücretsiz Rapor</button>
          </div>
       

        <div className="hero">
          <div>We believe in data.<span role="img">✌️</span></div>     
        </div>
        
       
       
        <div className="clients container">
          <div className="clients-content ">
            <p class="brandss">Çalıştığımız markalara ait bazı rakamlar.</p>
            <p>Müşterilerimizin gücüne nasıl güç kattığımızı aşağıdaki verileri inceleyerek öğrenebilirsiniz.</p>
          </div>
          <div className="clients-customers">
            <div className="clients-customers__sefa-stone">
              <p>e-canta.com.tr</p>
              <span>+%26</span>
              <p className="mh-42">REKLAM MAALİYETİNİN DÜŞÜRÜLMESİ</p>
              <Link to="/basari-hikayeleri/ecanta/">Çalışmayı inceleyin.</Link>
            </div>
            <div className="clients-customers__towelnrobe">
              <p>matbuu.com</p>
              <span>+%150</span>
              <p className="mh-42">ORGANİK TRAFİK ARTIŞI</p>
              <Link to="/basari-hikayeleri/matbuu/">Çalışmayı inceleyin.</Link>
            </div>
            <div className="clients-customers__robesnmore">
              <p>pakmaya.com.tr</p>
              <span>10x</span>
              <p className="mh-42">ORGANİK TRAFİK ARTIŞI</p>
              <Link to="/basari-hikayeleri/pakmaya/">Çalışmayı inceleyin.</Link>
            </div>
          </div>
          <Link to="/markalar/" className="red-button">Tüm Çalışmalarımızı Keşfedin</Link>
        </div>

        <div className="referances container py60">
          <h2 style={{ marginBottom:40}}>Hizmet verdiğimiz markalar.</h2>
          {/* <img alt="alt text" src={refImg} /> */}
          <div style={{marginBottom:30}}>
          <Companys />
          </div>
        </div>
        <SolvedSection /> 

        <div className="who-we-are container" >
          
          <div className="who-we-are__content col-md-4">
            <h2>Biz kimiz?</h2>
            <p>Tahmin ettiğinizden daha <br /> büyük bir etki yaratmanız <br /> için çalışıyoruz.</p>
            <p style={{width:286}}>Fark yaratmayı seven, üretken, sürekli kendini geliştiren, dijital pazarlama yöntemlerinin tüm alanlarında uzmanlaşmış ekibimiz ile markanıza özel hizmet veriyoruz.</p>
            <Link to="/hakkimizda/">Ekibimizle tanışın.</Link>
          </div>
          <div className="col-md-8">
          <img alt="Flatart Ekip" src={whoWeAreImg1} />
          </div>
        </div>

        <div className="services container py60">
          <div className="services-help">
            <span>Analiz <svg className="arrow-right" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 563 1061.4" space="preserve">
            <polygon points="123.1,0 0,100.7 356.3,536.2 0.3,958.9 121.9,1061.4 563,537.6 " />
          </svg> Trafik Edinme <svg className="arrow-right" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 563 1061.4" space="preserve">
            <polygon points="123.1,0 0,100.7 356.3,536.2 0.3,958.9 121.9,1061.4 563,537.6 " />
          </svg> Dönüşüm</span>
            <h2>İhtiyaçlarınız için <br /> sunduğumuz hizmetler.</h2>
            <p>Dijital dünyada elinizi güçlendirerek fiziksel dünyanıza yatırım yapıyoruz.</p>
          </div>
          <div className="services-list">
            <div className="services-list__item">
              <div className="col-md-6 col-sm-12">
                <img alt="alt text" src={analyseImg} />
                </div>
              <div className="col-md-6 col-sm-12 services-list__item-content">
                <span>— Analiz</span>
                <h2>Veri analizi.<br />Markanıza özel plan.</h2>
                <p>Hedef kitlenizi inceliyor, analiz bilgilerini ilişkilendirme metodlarımızla birlikte dijital aksiyonlar için kullanıyoruz.</p>
                <Link to="/analiz/">Analiz sürecine göz atın.</Link>
              </div>
            </div>
            <div className="services-list__item acquire-reverse" style={{ padding: '0px 85px' }}>
              <div className="col-md-6 col-sm-12 services-list__item-content" >
                <span>— Trafik Edinme</span>
                <h2 style={{ width: '85%' }}>Yüksek kaliteli trafik.</h2>
                <p>Potansiyel müşterilerinizle buluşmanızı sağlayacak dijital çalışmalar kurguluyor, trafik edinme projeleri yürütüyoruz. </p>
                <Link to="/trafik-edinme/">Trafik edinme sürecine göz atın.</Link>
              </div>
              <div className="col-md-6 col-sm-12 pt15">
                <img alt="alt text" class="convert-img" src={acquireImg} />
              </div>
            </div>

            <div className="services-list__item">
              <div className="col-md-6 col-sm-12"><img alt="alt text" class="convert-img" src={convertImg} /></div>
              <div className="col-md-6 col-sm-12 services-list__item-content">
                <span>— Dönüşüm</span>
                <h2>Gerçekleşen hedefler.<br />Kazandıran trafik.</h2>
                <p>Markanızla etkileşime geçen kullanıcıların hedeflenen dönüşümü gerçekleştirmesini sağlıyoruz.</p>
                <Link to="/donusum/">Dönüşüm sürecine göz atın.</Link>
              </div>
            </div>
          </div>

        </div>

        <div className="choose-us pb60 container neden-biz">
          <div className="choose-us_box">
            <img alt="alt text" className="design-squad" src={squad} loading="lazy" />
            <h2>Neden bizimle çalışmalısınız?</h2>
            <p className="choose-us_desc">Markanızın geleceğini veri analitiği ile inşa ediyor, dijital<br /> pazarlama alanındaki deneyimimizi sizin için sergiliyoruz. </p>
            <div className="choose-us_box__features">

              <div className="choose-feature">
                <img alt="alt text" src={shopifyEcommerce} loading="lazy"></img>
                <div className="choose-feature_content">
                  <h3>Shopify Eticaret Uzmanıyız.</h3>
                  <p>Dünyanın en çok tercih edilen e-ticaret platformunda sizin için yer ayırabiliriz.</p>
                </div>
              </div>

              <div className="choose-feature">
                <img alt="alt text" src={costFriendly} loading="lazy"></img>
                <div className="choose-feature_content">
                  <h3>Bütçe Dostuyuz.</h3>
                  <p>En etkili sonuçları, en uygun fiyatlara alın.</p>
                </div>
              </div>

              <div className="choose-feature">
                <img alt="alt text" src={resultBased} loading="lazy"></img>
                <div className="choose-feature_content">
                  <h3>Sonuç Odaklı Bir Ajansız.</h3>
                  <p>Tüm çalışmalarımızın sonuçlarını sizinle paylaşıyoruz.</p>
                </div>
              </div>

              <div className="choose-feature">
                <img alt="alt text" src={transparencyIsKey} loading="lazy"></img>
                <div className="choose-feature_content">
                  <h3>Şeffaf İş Süreçlerine Sahibiz.</h3>
                  <p>Dijital faaliyetlerimizin her aşamasında sizi bilgilendiriyoruz.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        

        <Checkup/>

        <Testimonial />

      </Layout >
    )
  }
}
export default Home
