import React from 'react';
import one from "../assets/img/one-1.webp";
import two from "../assets/img/two-2.webp";
import three from "../assets/img/three-3.webp";

const SolvedCard = ({ title, description, imageUrl, link,listItems,textp  }) => (
  <div className="solved-card">
    <div className="card-body">
      <div className="card-title">{title}</div>
      <div className="card-text">
        <p>{description}</p>
        {listItems && (
          <ul>
            {listItems.map((item, index) => (
              <li key={index} style={{ fontWeight: 400 }} aria-level="1">
                <span className='markernew'>•</span> <span style={{ fontWeight: 400 }}>{item}</span>
              </li>
            ))}
          </ul>
        )}
        
      </div>
    </div>
    <div className="card-img">
      <img loading="lazy" width="100" height="100" src={imageUrl} className="img-responsive" alt="Icon" />
    </div>
    <div className="card-footer">
      <a href={link} className="btn card-btn ignore-external">
        {textp} 
      </a>
    </div>
  </div>
);

const SolvedSection = () => (
  <div className="container news">
    <h1 className="solved-section-title">360° Dijital Pazarlama Hizmetleri</h1>
    <p className="solved-section-subtitle">Flatart, 360 derece dijital pazarlama çözümleri ve alanında uzman ekibiyle tüm sorunlarınıza çözüm üretir.</p>
    <div className="row sp_40 flex_row">
      {/* Solved Card 1 */}
      <div className="col-xs-12 col-md-6 solved-card-col solved-card-col1 teps">
        <SolvedCard
          title="Dijital kampanyalarımızı yönetmek çok zaman alıyor"
          description="Dijital kampanyalarınızı yönetmek için sayısız saat harcamaktan ve üstelik istediğiniz sonuçları alamadığınızı hissetmekten bıktınız mı? Flatart olarak, dijital pazarlamanın bunaltıcı ve zaman alıcı olabileceğini biliyoruz, fakat merak etmeyin, bir çözüm var: alanında uzman bir dijital ajansla ortaklık kurmak!"  
          imageUrl={one} 
          textp="Digital Ads Çözümlerimizi Keşfedin"
          link="https://flatart.com.tr/digital-ads/"
          listItems={[
            'Zaman Verimliliği',
            'En Güncel Araçlar ve Teknoloji',
            'Aylık Raporlarla Kanıtlanmış Sonuçlar'
          ]}
        />
      </div>
      {/* Solved Card 2 */}
      <div className="col-xs-12 col-md-6 solved-card-col solved-card-col2">
        <SolvedCard
          title="Web sitemiz yeterince trafik almıyor"
          description="Web sitenizin yeterince trafik almaması sizi hayal kırıklığına mı uğrattı? Günümüzde bir web sitesine sahip olmak dijital görünürlülüğünüzü artırmak için tek başına yeterli değil. SEO, web sitenizin tüm potansiyelini ortaya çıkarmanın en büyük anahtarıdır. Flatart ile iş birliği yaparak, yalnızca web sitenizin trafiğini artırmakla kalmayacak, geleceğe yatırım yaparak çevrimiçi varlığınızı ve dönüşüm oranlarınızı da arttıracaksınız!"
          textp="SEO Çözümlerimizi Keşfedin"
          imageUrl={two} 
          link="/seo-ajansi/"
          listItems={[
            'Markanıza ve Sektörünüze Özel Stratejiler',
            'Algoritma Güncellemelerine Hızlı Adaptasyon',
            'Doğru ve Kaliteli İçerik Üretimi'
          ]}
        />
      </div>
      {/* Solved Card 3 */}
      <div className="col-xs-12 col-md-6 solved-card-col solved-card-col3 news">
        <SolvedCard
          title="Dijital pazarlamaya yatırım yapıyor ancak istediğimiz sonuçları göremiyoruz"
          description="Dijital pazarlama çalışmalarına kaynak aktarıyor ancak sonuçların beklentilerinizin altında kaldığını mı görüyorsunuz? Endişelenmeyin. Birçok şirket, dijital pazarlamanın karmaşık dünyasında gezinirken benzer zorluklarla karşılaşıyor. İşte bu noktada Flatart gibi uzman bir dijital pazarlama ajansı sizler için oyunun kurallarını değiştirebilir!"
          textp="Dijital Danışmanlık Çözümlerimizi Keşfedin"
          imageUrl={three}
          link="https://flatart.com.tr/digital-danismanlik/"
          listItems={[
            'Markanıza Özel Ölçümlenebilir Hedefler',
            'Veriye Dayalı Kararlar',
            'Maliyet Verimliliği'
          ]}
        />
      </div>
     
    
    </div>
  </div>
);

export default SolvedSection;
